.container12 {
  width: calc(100% - 100px);
  padding: 20px;
  padding-top: 30px;
}

.topContainer12 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topContainer12 .inputBox {
  width: 350px;
  height: 45px;
  position: relative;
}

.topContainer12 .inputBox input {
  width: 100%;
  height: 100%;
  background: #19162c;
  border: none;
  outline: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  color: #d0cfd4;
  padding: 0px 40px;
  font-size: 16px;
  border-radius: 6px;
}

.topContainer12 .inputBox input::placeholder {
  color: #757380;
}

.topContainer12 .inputBox i {
  position: absolute;
  left: 0;
  top: 0;
  color: #757380;
  font-size: 20px;
  width: 40px;
  height: 45px;
  text-align: center;
  line-height: 45px;
}

.profileContainer12 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d0cfd4;
  position: relative;
}

.profileContainer12 .profileIcon {
  width: 40px;
  height: 40px;
  background: #19162c;
  border-radius: 6px;
  text-align: center;
  line-height: 40px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  font-size: 18px;
  position: relative;
}

.profileContainer12 .profileIcon::before {
  position: absolute;
  content: "";
  top: 5px;
  right: 10px;
  width: 8px;
  height: 8px;
  background: #b1147d;
  border-radius: 50%;
}

.profileContainer12 .profileImage {
  width: 40px;
  height: 40px;
  background: #555;
  border-radius: 100%;
  margin: 0px 10px;
  overflow: hidden;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}

.profileContainer12 .profileImage img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profileContainer12 .profileName {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.profileContainer12 .menuChevron {
  cursor: pointer;
  font-size: 12px;
  margin: 0px 10px;
}

.profileContainer12 .menuChevron:hover {
  transition: transform 0.5s ease;
}

.profileContainer12 .menuContainer {
  position: absolute;
  right: 10px;
  top: 50px;
  background: #19162c;
  border-radius: 10px;
  width: 180px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
  transform: translateX(300px);
  transition: transform 0.3s cubic-bezier(0.17, 0.81, 0.38, 1.39);
}

.profileContainer12 .menuContainer:after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  background: #19162c;
  top: -5px;
  right: 14px;
  transform: rotate(45deg);
}

.profileContainer12 .menuContainer ul {
  width: 100%;
  height: 100%;
}

.profileContainer12 .menuContainer ul li {
  list-style: none;
  margin: 5px 0;
  width: 100%;
  padding: 10px 20px;
  cursor: pointer;
  color: #757380;
  text-align: left;
}

.profileContainer12 .menuContainer ul li:hover {
  background: #121026;
  transition: 0.3s;
  color: #f1f1f1;
}

@media screen and (max-width: 525px) {
  .container12 {
    width: calc(100% - 68px);
  }

  .topContainer12 .inputBox {
    width: 120px;
    z-index: 101;
  }

  .topContainer12 .inputBox input:focus {
    width: 320px;
    transition: width 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}
