.profile-card {
  position: relative;
  background-color: white;
  height: 180px;
  width: 180px;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
  margin: 0px;
}

.profile-card:hover {
  border-radius: 10px;
  height: 190px;
  width: 190px;
}

.profile-card:hover .img-container {
  border-radius: 10px;
}
.profile-card:hover .img-container img{
  border-radius: 10px;
  transform: scale(0.9);
}

.img-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: 0.6s;
  z-index: 99;
}

.img-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  transition: 0.6s;
  aspect-ratio: 1/1;
  object-fit: contain;
  mix-blend-mode: color-burn;
}
.coin {
  height: 55px;
  width: 55px;
  animation: rotationCoin 10s infinite linear;
  z-index: -2 !important
}

#roadmap .coin1 {
  left: 0;
  top: 20px
}

@-webkit-keyframes rotationCoin {
  from {
      -webkit-transform: rotate(0deg)
  }
  to {
      -webkit-transform: rotate(359deg)
  }
}
@keyframes rotationCoin {
  from {
      -webkit-transform: rotate(0deg)
  }
  to {
      -webkit-transform: rotate(359deg)
  }
}