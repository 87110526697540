.sec-title {
    font-weight: 400;
    line-height: 1;
    color: #F4EDDF;
    padding-bottom: 20px
}
.desc {
    line-height: 1.8;
    color: #BBB3B2;
    letter-spacing: 0.01rem
}
.shape6 {
    position: absolute;
    top: 152%;
    left: 0;
    z-index: 1;
    width: 100%
}