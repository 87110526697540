menu {
  width: 50px;
  height: 50%;
  background: #19162c;
  display: flex;
  
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  position: sticky;
  top: 0;
}
.hoverable{
  cursor: url('../../assets/blood.svg'), auto;
}

.mymenu{
  display: flex;
  justify-content: center;
  align-items: center;

}

menu ul {
  padding-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;  position: sticky
 
}

menu ul li {
  list-style: none;
  margin: 20px 0;
  position: relative;
  color: #8c8a95;
  width: 100%;
  text-align: center;
}

menu ul li a {
  font-size: 24px;
  color: #8c8a95;
  text-decoration: none;
  transition: all 0.3s;
}

menu ul li a::before {
  position: absolute;
  content: "";
  top: 5px;
  left: -10px;
  width: 0px;
  height: 20px;
  background: #b1147d;
  border-radius: 50%;
}

menu ul li:hover a::before {
  width: 20px;
  transition: all 0.3s;
}

menu ul li.active a::before {
  width: 20px;
  transition: all 0.3s;
}

menu ul li.active a {
  color: #b1147d;
}

menu ul li:hover a {
  color: #b1147d;
}

.lastMenu {
  margin-top: auto;
}

@media only screen and (max-width: 525px) {
  menu {
    min-width: 68px;
    position: fixed;
    top: 25%;
    left: 0%
  }
}
.icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-container {
  position: absolute;
  top: 0;
  left: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.sparkle-button {
  display: none;
}

.menu-item:hover .sparkle-button {
  display: flex;
  background: linear-gradient(135deg, #230c3c, #f39c12);
  border: none;
  border-radius: 50px;
  padding: 10px 20px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.menu-item:hover .sparkle-button {
  transform: scale(1.1);
  opacity: 1;
}

.text {
  display: inline-block;
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  -webkit-background-clip: text;
  color: transparent;
}