.flip-card {
    background-color: transparent;
    width: 190px;
    height: 254px;
    perspective: 1000px;
    font-family: sans-serif;
    padding: 5px
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 2px solid rgb(104, 26, 104);
    border-radius: 1rem;
  }
  
  .flip-card-front {
    background: linear-gradient(120deg, rgb(245, 196, 255) 60%, rgb(252, 222, 255) 88%, rgb(242, 195, 255) 40%, rgba(238, 80, 255, 0.603) 48%);
  }
  
  .flip-card-back {
    background: linear-gradient(120deg, rgb(245, 196, 255) 60%, rgb(252, 222, 255) 88%, rgb(242, 195, 255) 40%, rgba(238, 80, 255, 0.603) 78%);
    transform: rotateY(180deg);
  }
    
  .flip-card-image {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    object-fit: cover; /* Adjust this property to fit your image properly */
  }