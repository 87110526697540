.App1 {
    width: 100%;
    height: 100vh;
    background: #121026;
    display: flex;
    overflow: hidden;
  }
  
  .App1::-webkit-scrollbar {
    display: none;
  }
  
  @media only screen and (max-width: 525px) {
    .App1 {
      overflow-x: hidden;
      overflow-y: scroll;
    }
  }