.card {
overflow: visible;
width: 270px;
height: 380px;
}

.content {
width: 100%;
height: 100%;
transform-style: preserve-3d;
transition: transform 300ms;
box-shadow: 0px 0px 10px 2px #000000ee;
border-radius: 5px;
}

.back {
background-color: #151515;
position: absolute;
width: 100%;
height: 100%;
border-radius: 5px;
overflow: hidden;
justify-content: center;
display: flex;
align-items: center;
}

.back::before {
position: absolute;
content: ' ';
display: block;
width: 160px;
height: 160%;
background: linear-gradient(90deg, transparent, #29aac7, #29aac7, #29aac7, #7629c7, transparent);
animation: rotation_481 5000ms infinite linear;
}

.back-content {
position: absolute;
width: 99%;
height: 99%;
background-color: #151515;
border-radius: 5px;
color: white;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 30px;
}

@keyframes rotation_481 {
0% {
    transform: rotateZ(0deg);
}

0% {
    transform: rotateZ(360deg);
}
}